import { createEventParams as createKMPEventParams } from './kmp'

export default class BaseApi {
  constructor() {
    this.registrationReferrer = null
    this.referringPage = null
  }

  setReferralInfo(registrationReferrer, referringPage) {
    if (registrationReferrer) {
      this.registrationReferrer = registrationReferrer
    }
    if (referringPage) {
      this.referringPage = referringPage
    }
  }

  createEventParams(pageName, openType) {
    return createKMPEventParams(
      pageName,
      openType,
      this.registrationReferrer,
      this.referringPage,
    )
  }
}
