import { IDENTITY_SDK_VERSION } from '../version'

const findIdentitySdkScript = () => {
  // Try to find the script by its ID first
  let script = document.getElementById('nbc-identity-sdk-js')

  // If no script with the ID is found, use querySelector to find by src
  if (!script) {
    script = document.querySelector('script[src*="identity-sdk"]')
  }

  return script
}

export const getSDKVersionFromScript = () => {
  const sdkScript = findIdentitySdkScript()
  if (!sdkScript) {
    return IDENTITY_SDK_VERSION
  }
  return getVersionFromUrl(sdkScript.src)
}

export const getVersionFromUrl = (url) => {
  const versionPattern = /v(\d+[.\d+-]*)\//
  const match = url.match(versionPattern)

  if (!match) {
    return IDENTITY_SDK_VERSION
  }

  return match[1]
}
