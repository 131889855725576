import {
  checkVersionIsAtLeast,
  checkVersionIsEqualTo,
} from './versionHelper'

/**
 * SMS Consent must be sent to BackEnd/MParticle,
 * we must store these keys to handle them properly.
 */
export const KnownOptIns = Object.freeze({
  SMS_CONSENT: 'sms_consent',
})

const REMOTELY_STORED_OPT_INS = [
  KnownOptIns.SMS_CONSENT,
]

export function getConfigOptIns(config) {
  let optInsList = []

  if (checkVersionIsEqualTo({ currentVersion: config.schemaVersion, expectedVersion: '2.0' })) {
    optInsList = config.terms.optIns ?? []
  } else if (checkVersionIsAtLeast({ currentVersion: config.schemaVersion, minVersion: '2.1' })) {
    optInsList = config.optIns.checkboxes
      .filter((item) => REMOTELY_STORED_OPT_INS.indexOf(item.identifier) < 0)
  }

  return optInsList
}

export function getOptInsForProfileCompleteCheck(config) {
  let optInsList = []

  if (checkVersionIsEqualTo({ currentVersion: config.schemaVersion, expectedVersion: '2.0' })) {
    optInsList = config.terms.optIns ?? []
  } else if (checkVersionIsAtLeast({ currentVersion: config.schemaVersion, minVersion: '2.1' })) {
    const completeProfileOptIns = config.optIns.completeProfile ?? []
    optInsList = getConfigOptIns(config)
      .filter((item) => completeProfileOptIns.indexOf(item.identifier) >= 0)
  }

  return optInsList
}
